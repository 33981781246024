//P1 images
import L1I1 from "./lease1_img1.jpg";
import L1I2 from "./lease1_img2.jpg";
import L1I3 from "./lease1_img3.jpg";
//P2 images
import L2I1 from "./lease2_img1.jpg";
import L2I2 from "./lease2_img2.jpg";
import L2I3 from "./lease2_img3.jpg";
//P3 images
import L3I1 from "./lease3_img1.jpg";
import L3I2 from "./lease3_img2.jpg";
//P4 images
import L4I1 from "./lease4_img1.jpg";
import L4I2 from "./lease4_img2.jpg";
//P5 images
import L5I1 from "./lease5_img1.jpg";
import L5I2 from "./lease5_img2.jpg";
//P6 images
import L6I1 from "./lease6_img1.jpg";
import L6I2 from "./lease6_img2.jpg";

export const LeaseImages = {
    L1: [ L1I1, L1I2, L1I3 ],
    L2: [ L2I1, L2I2, L2I3 ],
    L3: [ L3I1, L3I2 ],
    L4: [ L4I1, L4I2 ],
    L5: [ L5I1, L5I2 ],
    L6: [ L6I1, L6I2 ]
};
