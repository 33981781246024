import React from "react";
// import { Members } from "../Static/Members.static";
// import { IMember } from "../Interface/IMember.interface";
import { Timelines } from "../Static/Timeline.static";
import { ITimeline } from "../Interface/ITimeline.interface";
import { useTranslation } from "react-i18next";
import VideoBlockView from "../../MainPage/View/VideoBlock.view";
import "./Less/AboutUs.css";

export interface IAboutUsProps {}

const AboutUs: React.FC<IAboutUsProps> = (props): React.ReactElement => {
    const { t, i18n } = useTranslation();
    return (
        <div className="about-us">
            <div className="about-us__container">
                <div className="about-us__container--left">
                    <h1 className="about-us__title">{t("aboutUsTitle")}</h1>
                    <h3 className="about-us__title-small">{t("aboutUsSmallT1")}</h3>

                    <p className="about-us__paragraph">{t("aboutUsText")}</p>

                    {i18n.language === "hu" && (
                        <VideoBlockView
                            title={"Xanga Group Advertisement"}
                            videoUrl={t("advertisementVideoUrl")}
                        />
                    )}

                    <h3 className="about-us__title-small">{t("aboutUsSmallT2")}</h3>

                    {Timelines.map((timeline: ITimeline, index: number) => (
                        <div className="about-us__timeline" key={`${index}-timeline`}>
                            <div className="about-us__timeline--date">{timeline.date}</div>
                            <div
                                className={`about-us__timeline--dot ${index === Timelines.length - 1
                                    ? "no-next"
                                    : ""}`}
                            >
                                <span className="circle" />
                            </div>
                            <div className="about-us__timeline--text">{t(timeline.text)}</div>
                        </div>
                    ))}
                </div>
                {/* <div className="about-us__container--right">
					<h1 className="about-us__title">{t("aboutUsOurTeam")}</h1>

					{Members.map((person: IMember, index: number) => (
						<div className="about-us__team" key={`${index}-member`}>
							<div className="about-us__team--text">
								<span className="circle" />
								<h4 className="about-us__team--name">{person.name}</h4>
								<h6 className="about-us__team--position">{person.position}</h6>
							</div>
						</div>
					))}
				</div> */}
            </div>
        </div>
    );
};

export default AboutUs;
