import IconUrl2 from "../../../Assets/officeCircle.png";
import IconUrl3 from "../../../Assets/flightCircle.png";
import IconUrl4 from "../../../Assets/gearCircle2.png";
import { ICardData } from "../Interface/ICardData.interface";

export const CardData: ICardData[] = [
    {
        header: "contactUsH1",
        address: "H-4025 Debrecen, Simonffy street 4-6. I/123, Hungary",
        image: IconUrl2,
        fax: "",
        phone: "+36 30 737 6722",
        email: "iroda@xponential.hu",
        web: "https://www.xponential.hu"
    },
    {
        header: "contactUsH2",
        address: "H-4025 Debrecen, Határ street 1., Hungary",
        image: IconUrl4,
        fax: "+36 52 534 951",
        phone: "+36 52 534 950",
        email: "info@xanga.hu",
        web: "https://www.xanga.hu"
    },
    {
        header: "contactUsH3",
        address: "H-4030 Debrecen, Repülőtéri street 12., Hungary",
        image: IconUrl3,
        fax: "+36 52 534 951",
        phone: "+36 52 534 950",
        email: "info@xanga.hu",
        web: "https://www.xanga.hu"
    },
    {
        header: "contactUsH4",
        address: "H-4025 Debrecen, Simonffy street 4-6. I/121, Hungary",
        image: IconUrl2,
        fax: "+36 52 534 951",
        phone: "+36 52 534 950",
        email: "info@xanga.hu",
        web: "https://www.xanga.hu"
    },
    {
        header: "contactUsH5",
        address: "H-4025 Debrecen, Simonffy street 4-6. I/121, Hungary",
        image: IconUrl2,
        fax: "+36 52 534 951",
        phone: "+36 52 534 950",
        email: "info@xangaventures.com",
        web: "https://www.xangaventures.com"
    }
];
