//Location 1 images
import Loc1Img1 from "../../../Assets/loc1_img1.jpg";
import Loc1Img2 from "../../../Assets/loc1_img2.jpg";
import Loc1Img3 from "../../../Assets/loc1_img3.jpg";
import Loc1Img4 from "../../../Assets/loc1_img4.jpg";

//Location 2 images
import Loc2Img1 from "../../../Assets/loc2_img1.jpg";
import Loc2Img2 from "../../../Assets/loc2_img2.jpg";
import Loc2Img3 from "../../../Assets/loc2_img3.jpg";

//Location 3 images
import Loc3Img1 from "../../../Assets/loc3_img2.jpg";
import Loc3Img2 from "../../../Assets/loc3_img3.jpg";
import Loc3Img3 from "../../../Assets/loc3_img4.jpg";

//Location 4 images
import Loc4Img1 from "../../../Assets/loc4_img1.jpg";
import Loc4Img2 from "../../../Assets/loc4_img2.jpg";
import Loc4Img3 from "../../../Assets/loc4_img3.jpg";

//Location 5 images
import Loc5Img1 from "../../../Assets/loc5_img1.png";

import { IElementDataView } from "../Interface/IElementData.interface";

export const LocationData: IElementDataView = {
    location1: {
        images: [Loc1Img1, Loc1Img2, Loc1Img3, Loc1Img4],
        titleCode: "loc1Title",
        textBlocks: [
            {
                title: "",
                text: "loc1TXT1"
            },
            {
                title: "loc1SMT2",
                text: "loc1TXT2"
            },
            {
                title: "loc1SMT3",
                text: "loc1TXT3"
            }
        ],
        coords: {
            lat: 47.520054,
            lng: 21.574851
        },
        path: "/locations/1",
        other1: "location2",
        other2: "location3",
        other3: "location4",
        other4: "location5"
    },
    location2: {
        images: [Loc2Img1, Loc2Img3, Loc2Img2],
        titleCode: "loc2Title",
        textBlocks: [
            {
                title: "",
                text: "loc2TXT1"
            },
            {
                title: "loc2SMT2",
                text: "https://www.xponential.hu",
                isLink: true
            }
        ],
        coords: {
            lat: 47.52895,
            lng: 21.623104
        },
        path: "/locations/2",
        other1: "location1",
        other2: "location3",
        other3: "location4",
        other4: "location5"
    },
    location3: {
        images: [Loc3Img1, Loc3Img2, Loc3Img3],
        titleCode: "loc3Title",
        textBlocks: [
            {
                title: "",
                text: "loc3TXT1"
            },
            {
                title: "loc3SMT2",
                text: "loc3TXT2"
            }
        ],
        coords: {
            lat: 47.49225,
            lng: 21.607159
        },
        path: "/locations/3",
        other1: "location1",
        other2: "location2",
        other3: "location4",
        other4: "location5"
    },
    location4: {
        images: [Loc4Img2, Loc4Img1, Loc4Img3],
        titleCode: "loc4Title",
        textBlocks: [
            {
                title: "",
                text: "loc4TXT1"
            }
        ],
        coords: {
            lat: 47.480623,
            lng: 21.628958
        },
        path: "/locations/4",
        other1: "location1",
        other2: "location2",
        other3: "location3",
        other4: "location5"
    },
    location5: {
        images: [Loc5Img1],
        titleCode: "loc5Title",
        textBlocks: [
            {
                title: "",
                text: "loc5TXT1"
            },
            {
                title: "loc5SMT2",
                text: "https://www.xangaventures.com",
                isLink: true
            }
        ],
        path: "/locations/5",
        other1: "location1",
        other2: "location2",
        other3: "location3",
        other4: "location4"
    }
};
