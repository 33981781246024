import { ILocationCard } from "./../Interface/ILocationCard.interface";
import LocationImage1 from "../../../Assets/loc1_img1.jpg";
import LocationImage2 from "../../../Assets/location2.jpg";
import LocationImage3 from "../../../Assets/loc3_img2.jpg";
import LocationImage4 from "../../../Assets/loc4_img2.jpg";
import IconUrl1 from "../../../Assets/gearCircle1.png";
import IconUrl2 from "../../../Assets/officeCircle.png";
import IconUrl3 from "../../../Assets/flightCircle.png";
import IconUrl4 from "../../../Assets/gearCircle2.png";

export const Locations: ILocationCard[] = [
    {
        className: "main-page-location-marker-1 map-marker",
        imageUrl: LocationImage1,
        iconUrl: IconUrl4,
        title: "location1",
        link: "/locations/1",
        markerID: "marker1"
    },
    {
        className: "main-page-location-marker-2 map-marker",
        imageUrl: LocationImage2,
        iconUrl: IconUrl2,
        title: "location2",
        link: "/locations/2",
        markerID: "marker2"
    },
    {
        className: "main-page-location-marker-3 map-marker",
        imageUrl: LocationImage3,
        iconUrl: IconUrl3,
        title: "location3",
        link: "/locations/3",
        markerID: "marker3"
    },
    {
        className: "main-page-location-marker-4 map-marker",
        imageUrl: LocationImage4,
        iconUrl: IconUrl1,
        title: "location4",
        link: "/locations/4",
        markerID: "marker4"
    }
];
