import React from "react";
import {
    IElementData,
    IElementTextBlock,
    IElementDataView
} from "../Interface/IElementData.interface";
import { IHistory, PageWrapper } from "xa-generics";
import { useTranslation } from "react-i18next";
import { LocationData } from "../Static/LocationData.static";
import { IRouterProps } from "xa-navigation";
import { ProjectData } from "../Static/ProjectsData.static";
import { useHistory } from "react-router-dom";
import { LeaseData } from "../Static/LeaseData.static";
import RequestForQuotation from "../../RequestForQuotation/View/RequestForQuotation";
import LocationOneMap from "./LocationOneMap.view";
import BackButton from "../../BackButton/View/BackButton";
import Spinner from "../../Spinner/Spinner";
import Img from "react-image";
import "./Less/ElementView.css";

type T = { id: string };
//This is passed down from the App.routes.tsx file to the NavigationRouter
//The '/projects/:id' route passes 'project' and the '/locations/:id' passes 'location'.
export type IElementDataType = { dataSource: "location" | "project" | "lease" };

export type IElementDataViewProps = IRouterProps<T, IElementDataType>;

const ElementDataView: React.FC<IElementDataViewProps> = (props) => {
    const { t } = useTranslation();

    const key: string = `${props.dataSource}${props.computedMatch.params.id}`;
    const history: IHistory<void> = useHistory();

    // You can replace this with a switch-case if you need to add more pages with the same layout
    // Depending on the dataSource property, the DataSource will be initialized with different sources.
    const DataSource = (): IElementDataView => {
        switch (props.dataSource) {
            case "location":
                return LocationData;
            case "project":
                return ProjectData;
            case "lease":
                return LeaseData;
        }
    };

    //If the 'id' doesn't exist in the DataSource, return null and redirect the user to where they came from.
    const source: IElementDataView = DataSource();

    if (!source[key]) {
        history.goBack();
        return null;
    }
    //The data is following the same schema in any case which is described by ILocationData.
    //This will be used to render various data in the layout.
    const Data: IElementData = source[key];

    return (
        <React.Fragment>
            <PageWrapper>
                <div className="element-view-wrapper">
                    <div className="element">
                        <BackButton to={props.dataSource} />
                        <div className="element__main-title">{t(Data.titleCode)}</div>
                        {Data.textBlocks.map((element: IElementTextBlock, index: number) => (
                            <div
                                className="element__text-block"
                                key={`${index}-element-text-block`}
                            >
                                <div
                                    className={`element__text-block--title${element.isSmallTitle
                                        ? " --use-small-font"
                                        : ""}`}
                                >
                                    {t(element.title)}
                                </div>
                                {element.isLink ? (
                                    <a
                                        href={element.text}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        className="element__text-block--text"
                                    >
                                        {element.text}
                                    </a>
                                ) : (
                                    <div className="element__text-block--text">
                                        {t(element.text)}
                                    </div>
                                )}
                            </div>
                        ))}
                        {history.location.pathname.includes("lease") && <RequestForQuotation />}
                        {Data.coords && (
                            <LocationOneMap coords={Data.coords} markerText={t(Data.titleCode)} />
                        )}
                    </div>
                    <div className="image-container">
                        {Data.images.map((image: string, index: number) => (
                            <div className="container" key={`${index}-right-image`}>
                                <Img
                                    src={image}
                                    alt="Failed to load image..."
                                    loader={<Spinner />}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="other-elements-container">
                    <div className="title">
                        {t(
                            props.dataSource === "lease" ? "otherLeaseTitle" : "otherLocationsTitle"
                        )}
                    </div>
                    <div className="block">
                        <div className="text">
                            <span>{t(source[Data.other1].titleCode)}</span>
                        </div>
                        <button
                            className="button"
                            onClick={() => history.push(source[Data.other1].path)}
                        >
                            <span>{t("more")} </span>
                            <div className="arrow-container">
                                <span className="arrow" />
                            </div>
                        </button>
                    </div>
                    <div className="block">
                        <div className="text">
                            <span>{t(source[Data.other2].titleCode)}</span>
                        </div>
                        <button
                            className="button"
                            onClick={() => history.push(source[Data.other2].path)}
                        >
                            <span>{t("more")} </span>
                            <div className="arrow-container">
                                <span className="arrow" />
                            </div>
                        </button>
                    </div>
                    {Data.other3 && Data.other4 ? (
                        <React.Fragment>
                            <div className="block">
                                <div className="text">
                                    <span>{t(source[Data.other3].titleCode)}</span>
                                </div>
                                <button
                                    className="button"
                                    onClick={() => {
                                        if (Data.other3) history.push(source[Data.other3].path);
                                    }}
                                >
                                    <span>{t("more")} </span>
                                    <div className="arrow-container">
                                        <span className="arrow" />
                                    </div>
                                </button>
                            </div>
                            <div className="block">
                                <div className="text">
                                    <span>{t(source[Data.other4].titleCode)}</span>
                                </div>
                                <button
                                    className="button"
                                    onClick={() => {
                                        if (Data.other4) history.push(source[Data.other4].path);
                                    }}
                                >
                                    <span>{t("more")} </span>
                                    <div className="arrow-container">
                                        <span className="arrow" />
                                    </div>
                                </button>
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </PageWrapper>
        </React.Fragment>
    );
};

export default ElementDataView;
